/**
 * Open and close SelectStore menu
 *
 */

(function(){

	/* Events */

	$btnSelectStore.on("click", _openSelectStore);
	$btnCloseSelectStore.on("click", _closeSelectStore);

	$contentSelectStore.on("click", function(e){
		if ($(e.target).is(this)) {
			_closeSelectStore();
		};
	});


	/* Class Name*/
	var

	selectstoreOpen = "selectstore-open";

	selectstorePanelActive = "content-selectstore-active",
	selectstorePanelOpen = "content-selectstore-open";

	/* Functions */

	function _openSelectStore()
	{
		/**
		 * Show Sidemenu Panel
		 */
		$contentSelectStore.addClass(selectstorePanelActive);

		setTimeout(function(){

			$contentSelectStore.addClass(selectstorePanelOpen);
			$selectstore.addClass(selectstoreOpen);

		},100);
	}

	function _closeSelectStore()
	{
		/**
		 * Hide Sidemenu Panel
		 */
		$contentSelectStore.removeClass(selectstorePanelOpen);
		$selectstore.removeClass(selectstoreOpen);
		
		setTimeout(function(){

			$contentSelectStore.removeClass(selectstorePanelActive);

		},400);
	}

	/**
	 * API
	 * 
	 * @type {Object}
	 */
	window.app.selectstore = {

		open: _openSelectStore,
		close: _closeSelectStore

	};

	/* End */

})();