/**
 * Open and close SubCategories Products menu
 *
 */

(function(){

	/* Events */

	$btnSubCategoriesProducts.on("click", _openSubCategories);
	$btnCloseSubCategoriesProducts.on("click", _closeSubCategories);

	$contentSubCategoriesProducts.on("click", function(e){
		if ($(e.target).is(this)) {
			_closeSubCategories();
		};
	});


	/* Class Name*/
	var

	categoriesOpen = "categories-open";

	categoriesPanelActive = "content-categories-active",
	categoriesPanelOpen = "content-categories-open";

	/* Functions */

	function _openSubCategories()
	{
		/**
		 * Show Sidemenu Panel
		 */
		$contentSubCategoriesProducts.addClass(categoriesPanelActive);

		setTimeout(function(){

			$contentSubCategoriesProducts.addClass(categoriesPanelOpen);
			$subcategoriesProducts.addClass(categoriesOpen);

		},100);
	}

	function _closeSubCategories()
	{
		/**
		 * Hide Sidemenu Panel
		 */
		$contentSubCategoriesProducts.removeClass(categoriesPanelOpen);
		$subcategoriesProducts.removeClass(categoriesOpen);
		
		setTimeout(function(){

			$contentSubCategoriesProducts.removeClass(categoriesPanelActive);

		},400);
	}

	/**
	 * API
	 * 
	 * @type {Object}
	 */
	window.app.categoriesproducts = {

		open: _openSubCategories,
		close: _closeSubCategories

	};

	/* End */

})();