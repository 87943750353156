/**
 * Transform navbar into search input on click over the search icon
 * at the top navbar of the app
 * 
 */

(function(){

	/* Events */

	$contentSearch.on("scroll", _onScroll);

	$btnSearch.on("click", _openSearchBar);
	$btnCloseSearch.on("click", _closeSearchBar);


	/* Class Names */
	var

	searchActive = "navbar-search-active",
	searchOpen = "navbar-search-open",

	searchPanelActive = "content-search-active",
	searchPanelOpen = "content-search-open";


	/* Functions*/

	function _onScroll()
	{
		$inputSearch.blur();
	}

	function _openSearchBar()
	{
		/**
		 * Show Searchbar
		 */
		$navbarSearch.addClass(searchActive);
		$contentSearch.addClass(searchPanelActive);
		$html.addClass('html-block-scroll');

		setTimeout(function(){

			// Show Search
			$navbarSearch.addClass(searchOpen);

			// Focus
			$inputSearch.val("");

			// Hide Main Navbar
			window.app.navbar.hide();

			// Show Search Panel
			$contentSearch.addClass(searchPanelOpen);

		},100);

		$inputSearch.focus();
	}

	function _closeSearchBar()
	{
		/**
		 * Hide Searchbar
		 */
		if ($navbarSearch.hasClass(searchOpen))
		{
			$navbarSearch.removeClass(searchOpen);
			$inputSearch.blur();

			// Hide Search Panel
			$contentSearch.removeClass(searchPanelOpen);

			setTimeout(function(){
				$navbarSearch.removeClass(searchActive);
				$contentSearch.removeClass(searchPanelActive);
				$html.removeClass('html-block-scroll');

			},400);



			// Show Main Navbar
			window.app.navbar.show();
		};
		
		$html.removeClass('html-block-scroll');
	}

	/**
	 * API
	 * 
	 * @type {Object}
	 */
	window.app.search = {

		open: _openSearchBar,
		close: _closeSearchBar

	};
	

	/* End */

})();