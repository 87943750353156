var mailgunValidator = function mailgunValidator(parentSelector, emailElemName){
    function hideMailValidation(event){
        var $elem = $(event.target || event);
        var $form = $elem.closest("form");
        var fieldName = $elem[0].name;

        $form.find("." + fieldName + "-error, ." + fieldName + "-did-you-mean").hide();
    }

    function showFormValidationError(msg, event, data){
        var $elem = $(event.target);
        var $form = $(event.target).closest("form");
        var formId = $form[0].id;
        var fieldName = event.target.name;
        var errorName = fieldName + "-error";
        var didYouMeanName = fieldName + "-did-you-mean";


        $elem.attr("valid", false);

        if(formId == "subscribe-brand" || $form.hasClass("validate-email-mailgun")){
            if(data && data['did_you_mean']){
                var didYouMeanStr = data['did_you_mean'];

                $form.find("." + didYouMeanName + " span").text(didYouMeanStr);
                $form.find("." + errorName).hide();
                $form.find("." + didYouMeanName).show();
            }else{
                $form.find("." + errorName).show();
                $form.find("." + didYouMeanName).hide();
            }
            
        }
    }

    // function get_suggestion_str(is_valid, alternate) {
    //     if (is_valid) {
    //         var result = '<span class="success">Address is valid.</span>';
    //         if (alternate) {
    //             result += '<span class="warning"> (Though did you mean <em>' + alternate + '</em>?)</span>';
    //         }
    //         return result
    //     } else if (alternate) {
    //         return '<span class="warning">Did you mean <em>' + alternate + '</em>?</span>';
    //     } else {
    //         return '<span class="error">Address is invalid.</span>';
    //     }
    // }

    function validation_in_progress(event) {
        var $elem = $(event.target);

        hideMailValidation(event);
        $elem.addClass("validation-loading");
        $elem.attr("valid", false);
    }

    function validation_success(data, event) {
        $(event.target).removeClass("validation-loading");

        var $elem = $(event.target);
        var $form = $elem.closest("form");
        var $submitButton = $form.find('[type=submit]');

        if(data['is_valid']){
            var fieldName = event.target.name;
            
            hideMailValidation(event);
            $elem.attr("valid", data.is_valid);

            if($submitButton.data("pending-submit")){
                $submitButton.data("pending-submit", false);
                $form.submit();
            }
        }else{
            var msg = "Invalid email";
            showFormValidationError(msg, event, data);
            $submitButton.data("pending-submit", false);
        }
    }

    function validation_error(msg, event) {
        $(event.target).removeClass("validation-loading");
        showFormValidationError(msg, event);
    }

    $(parentSelector).on('click', '.did-you-mean span', function(event){
        var suggestedMail = $(this).text().trim();
        var $elem = $(parentSelector + ' [name="' + emailElemName + '"]');
        
        $elem.val(suggestedMail).focusout();
        hideMailValidation($elem);
    });

    $(parentSelector + ' [name="' + emailElemName + '"]').mailgun_validator({
        api_key: 'pubkey-d57e4c0a7633c4a5232aa49f89c1ebe5',
        in_progress: validation_in_progress,
        success: validation_success,
        error: validation_error,
    });
};

mailgunValidator("form:not(.spring-game form)", "subscriber");

(function(){

    /* Elements */
    $btnSubscribe = $(".open-subscribe"),
    $btnCloseSubscribe = $(".close-subscribe"),

    $contentSubscribe = $(".content-subscribe");

    if ($('#first-mobile-popup').hasClass('on-scroll')) {
        $(window).on('scroll', function(){
            if (!Cookies.get('subscribe.modal.v' + $('#first-mobile-popup').data('version')) && $('.small-iwant-btn').length) {
                $('.small-iwant-btn').trigger('click');
                Cookies.set('subscribe.modal.v' + $('#first-mobile-popup').data('version'), 1, { expires: 365 });
            }
        });
    }

    /* Events */

    $btnSubscribe.on("click", _openSelect);
    $btnCloseSubscribe.on("click", _closeSelect);

    $contentSubscribe.on("click", function(e){
        if ($(e.target).is(this)) {
            _closeSelect();
        };
    });


    /* Class Name */
    var

    selectOpen = "select-open";

    selectPanelActive = "content-select-active",
    selectPanelOpen = "content-select-open";

    /* Functions */

    function _openSelect()
    {
        $contentSubscribe.addClass(selectPanelActive);

        setTimeout(function(){
            $contentSubscribe.addClass(selectPanelOpen);
            $selectCity.addClass(selectOpen);
        },100);
    }

    function _closeSelect()
    {
        $contentSubscribe.removeClass(selectPanelOpen);
        $selectCity.removeClass(selectOpen);

        setTimeout(function(){
            $contentSubscribe.removeClass(selectPanelActive);
        },400);
    }


    /* Form */

    $.fn.serializeObject = function()
    {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };
    
    var $form = $('#subscribe-brand');

    $form.on('submit', _subscribeOrFail);

    function _hideForm()
    {
        $('.form-inputs').fadeOut(200);
    }

    function _message(msg)
    {
        return $("<div class='subscribe-message' style='display:none;'>"+msg+"</div>");
    }

    function _success(msg)
    {
        _hideForm();

        var $html = _message(msg);
            $html.addClass('success');

        $form.append($html);

        var $button = $("<div class='button button-tiny button-success close-subscribe' style='display:none;'>"+locale.continue+"</div>");

        $form.append($button);

        $html.delay(300).fadeIn(200);
        $button.delay(300).fadeIn(200);

        $button.on("click", _closeSelect);
    }

    function _error(msg)
    {
        _hideForm();

        var $html = _message(msg);
            $html.addClass('error');

        $form.append($html);

        var $button = $("<div class='button button-tiny button-error' style='display:none;'>"+locale.try_again+"</div>");

        $form.append($button);

        $html.delay(300).fadeIn(200);
        $button.delay(300).fadeIn(200);

        $button.on("click", function(){
            var $submitButton = $form.find("[type=submit]");
            $submitButton.removeAttr("disabled");

            $html.fadeOut(200);
            $button.fadeOut(200);


            $('.form-inputs').delay(300).fadeIn(200);
        });
    }

    function _subscribeOrFail(e){
        e.preventDefault();

        var $submitButton = $form.find("[type=submit]"),
            isValidEmail = $form.find('[name=subscriber]').attr('valid') == "true",
            gaEvent = ga_events.subscribe[page_name] || "Newsletter:brand:mobile";

        $submitButton.data("pending-submit", true);

        if(isValidEmail != true){
            return;
        }

        $submitButton.attr("disabled", true);
        $submitButton.data("pending-submit", false);

        var data = $form.serializeObject();

        $.ajax({
            method: "POST",
            url: '/ajx/ajax.file.php',
            data: data
        })
        .done(function( msg ) {
            response = JSON.parse( msg );

            if (response.status) {
                
                _success(response.msg);

                ga('send', 'event', gaEvent, 'click');
            }else{
                
                _error(response.msg);
            }
        });
    }

})();
