(function(){

	/* Events */

	$letterLink.on("click", _scrollToLetter );
	$contentLetters.on("scroll", _onScroll )

	/* Class Name */
	var

	classFocus = "focus";

	/* Chache */
	var

	isAnimating = false;

	/* Functions */

	function _scrollToLetter(e)
	{
		e.preventDefault();

		var 

		$letter = $(this), 
		$group = $($letter.attr("href"));

		if (!$group.length) return;

		var 

		scroll = $group.offset().top - 64,
		speed = scroll > 300 ? 400 : 200;

		isAnimating = true;

		$contentLetters.animate({
			scrollTop: scroll
		}, speed, function()
		{
			$group.parent().find("."+classFocus).removeClass(classFocus);
			$group.addClass(classFocus);

			setTimeout(function(){

				isAnimating = false;

			}, 100);
		});

	}


	function _onScroll()
	{

		if (isAnimating===true) { return };

		$(this).find("."+classFocus).removeClass(classFocus);
	}

})();