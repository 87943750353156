/**
 * Open and close Categories Products menu
 *
 */

(function(){

	/* Events */

	$btnCategoriesProducts.on("click", _openCategories);
	$btnCloseCategoriesProducts.on("click", _closeCategories);

	$contentCategoriesProducts.on("click", function(e){
		if ($(e.target).is(this)) {
			_closeCategories();
		};
	});


	/* Class Name*/
	var

	categoriesOpen = "categories-open";

	categoriesPanelActive = "content-categories-active",
	categoriesPanelOpen = "content-categories-open";

	/* Functions */

	function _openCategories()
	{
		/**
		 * Show Sidemenu Panel
		 */
		$contentCategoriesProducts.addClass(categoriesPanelActive);

		setTimeout(function(){

			$contentCategoriesProducts.addClass(categoriesPanelOpen);
			$categoriesProducts.addClass(categoriesOpen);

		},100);
	}

	function _closeCategories()
	{
		/**
		 * Hide Sidemenu Panel
		 */
		$contentCategoriesProducts.removeClass(categoriesPanelOpen);
		$categoriesProducts.removeClass(categoriesOpen);
		
		setTimeout(function(){

			$contentCategoriesProducts.removeClass(categoriesPanelActive);

		},400);
	}

	/**
	 * API
	 * 
	 * @type {Object}
	 */
	window.app.categoriesproducts = {

		open: _openCategories,
		close: _closeCategories

	};

	/* End */

})();