(function(){

	$(document).on("click", '.close-cookies-bar', function(){
        $cookiesBar = $(".cookies-bar");

		$cookiesBar.addClass("activate");

		$.post("//"+domain+"/ajx/ajax.file.php", {fn: 'cookiesAccept'}, function(data){
            $cookiesBar.addClass("fadeout");

            setTimeout(function(){
				$cookiesBar.remove();
			}, 400);
        });

	});

	$btnCloseNewCookies.on("click", function(){
		$cookiesBar.addClass("activate");
        $cookiesBar.addClass("fadeout");
        setTimeout(function(){
			$cookiesBar.remove();
		}, 400);
	});

})();