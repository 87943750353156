window.app = {};


/* Containers */
var 

$html = $('html'),
$content = $(".content-main"),
$contentHome = $(".content-homepage"),
$contentSearch = $(".content-search"),
$contentMenu = $(".content-sidemenu"),
$contentCategories = $(".content-categories"),
$contentCategoriesProducts = $(".content-categories-products"),
$contentSubCategoriesProducts = $(".content-subcategories-products"),
$contentSelectCity = $(".content-select-city"),
$contentSelectAddress = $(".content-select-address"),
$contentSelectStore = $(".content-selectstore"),
$contentLetters = $(".content-letters"), //selectcity
$contentLetters2 = $(".content-letters2"), //selectstore
$contentLetters3 = $(".content-letters3"), //subcategorie-products
$contentLettersGlobal = $(".content-letters-global"), //selectcity-global

$navbar = $(".navbar-main"),
$navbarSearch = $(".navbar-search"),

$sidemenu = $(".sidemenu"),
$categories = $(".categories"),
$categoriesProducts = $(".categories-products"),
$subcategoriesProducts = $(".subcategories-products"),
$selectCity = $(".select-city"),
$selectAddress = $(".select-address"),
$selectstore = $(".selectstore"),
$cookiesBar = $(".cookies-bar")
;


/**
 * Buttons
 */
var

$btnSearch = $(".open-search"),
$btnCloseSearch = $(".close-search"),

$btnMenu = $(".open-sidemenu"),
$btnCloseMenu = $(".close-sidemenu"),

$btnCategories = $(".open-categories"),
$btnCloseCategories = $(".close-categories"),

$btnCategoriesProducts = $(".open-categories-products"),
$btnCloseCategoriesProducts = $(".close-categories-products"),

$btnSubCategoriesProducts = $(".open-subcategories-products"),
$btnCloseSubCategoriesProducts = $(".close-subcategories-products"),

$btnSelectCity = $(".open-select-city"),
$btnCloseSelectCity = $(".close-select-city"),

$btnSelectAddress = $(".open-select-address"),
$btnCloseSelectAddress = $(".close-select-address"),

$btnSelectStore = $(".open-selectstore"),
$btnCloseSelectStore = $(".close-selectstore"),

$btnScrollToTop = $(".scrolltotop"),

$btnCloseCookies = $(".close-cookies-bar"),

$btnCloseNewCookies = $(".close-new-cookies-bar")
;


/**
 * Inputs
 */
var

$inputSearch = $navbarSearch.find(".input-search");


/**
 * Letters
 */
var

$letters = $(".letters"),
$letterLink = $letters.find("a"), //selectcity

$lettersGlobal = $(".letters-global"),
$letterLinkGlobal = $lettersGlobal.find("a"), //selectcity-global

$letters2 = $(".letters2"),
$letterLink2 = $letters2.find("a") //selectstore

$letters3 = $(".letters3"),
$letterLink3 = $letters3.find("a"); //subcategorie-products

var ga_events = {
	subscribe: {
		brand_city: 'newsletter:brand-city-mobile',
		brand: 'Newsletter:brand:mobile',
		brochure_view: 'newsletter:view-back-cover-mobile',
		home: 'newsletter:general-mobile',
		city_detail: 'newsletter:general-mobile'
	}
};