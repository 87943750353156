/**
 * Open and close Sidemenu
 *
 */

(function(){

	/* Events */

	$btnMenu.on("click", _openMenu);
	$btnCloseMenu.on("click", _closeMenu);

	$contentMenu.on("click", function(e){
		if ($(e.target).is(this)) {
			_closeMenu();
		};
	});


	/* Class Name*/
	var

	sidemenuOpen = "sidemenu-open";

	menuPanelActive = "content-sidemenu-active",
	menuPanelOpen = "content-sidemenu-open";

	/* Functions */

	function _openMenu()
	{
		/**
		 * Show Sidemenu Panel
		 */
		$contentMenu.addClass(menuPanelActive);
		$html.addClass('html-block-scroll');

		setTimeout(function(){

			$contentMenu.addClass(menuPanelOpen);
			$sidemenu.addClass(sidemenuOpen);

		},100);

		//load images in sidemenu upon open. Saves around 300kb and up to 14 requests
		bLazy.load($sidemenu.find('.b-lazy'));
	}

	function _closeMenu()
	{
		/**
		 * Hide Sidemenu Panel
		 */
		$contentMenu.removeClass(menuPanelOpen);
		$sidemenu.removeClass(sidemenuOpen);
		$html.removeClass('html-block-scroll');

		
		setTimeout(function(){

			$contentMenu.removeClass(menuPanelActive);

		},400);
	}

	/**
	 * API
	 * 
	 * @type {Object}
	 */
	window.app.sidemenu = {

		open: _openMenu,
		close: _closeMenu

	};

	/* End */

})();