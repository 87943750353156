/**
 * Open and close Sidemenu
 *
 */

(function(){

	/* Events */

	$content.on("scroll", _onScroll );
	$contentHome.on("scroll", _onScroll );

	$btnScrollToTop.on("click", _scrollToTop);
	
	/* Class Name*/
	var

	btnActive = "scrolltotop-active",
	btnShow = "scrolltotop-show";

	/* Cache */
	var

	isAnimating = false,

	isAnimatingShow = false,
	isAnimatingHide = false,

	timeout,

	scrollTopCache;

	/* Functions */

	function _scrollToTop()
	{
		var $scroll;

		

		/* on All Pages*/
		if ($content.length !== 0) {
			$scroll = $content;
		};

		/* on Home Page */
		if ($contentHome.length !== 0) {
			$scroll = $contentHome;
		};

		$scroll.animate({ scrollTop: 0 }, 400);
	}

	function _onScroll(event)
	{
		//if (isAnimating===true) { return };

		var $this = $(this), scrollTop, bottom;

		scrollTop = $this.scrollTop();
		bottom 	= $this[0].scrollHeight - $this.outerHeight();

		if (scrollTop > 800) {
			if (isAnimatingShow===true) { 
				scrollTopCache = scrollTop;
				return;
			};
			_showBtn();
		}else{
			if (isAnimatingHide===true) {
				scrollTopCache = scrollTop;
				return; 
			};
			_hideBtn();
		}

		scrollTopCache = scrollTop;
	}

	function _showBtn()
	{
		/**
		 * Show Btn
		 */
		$btnScrollToTop.addClass(btnActive);

		isAnimatingShow=true;

		if(timeout && isAnimatingHide===true) {
			clearTimeout(timeout);
			isAnimatingHide=false;
		}

		timeout = setTimeout(function(){
			$btnScrollToTop.addClass(btnShow);

			isAnimatingShow=false;
		},100);
	}

	function _hideBtn()
	{
		/**
		 * Hide Btn
		 */
		$btnScrollToTop.removeClass(btnShow);

		isAnimatingHide=true;

		if(timeout && isAnimatingShow===true) {
			clearTimeout(timeout);
			isAnimatingShow=false;
		}

		timeout = setTimeout(function(){
			$btnScrollToTop.removeClass(btnActive);

			isAnimatingHide=false;
		},400);
	}

	/**
	 * API
	 * 
	 * @type {Object}
	 */
	window.app.topbtn = {

		show: _showBtn,
		hide: _hideBtn

	};

	/* End */

})();