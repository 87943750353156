(function(){

    /* Elements */
    $btnSubscribe = $(".open-brand-info"),
    $btnCloseSubscribe = $(".close-brand-info"),

    $contentBrandInfo = $(".content-brand-info");

    /* Events */

    $btnSubscribe.on("click", _openSelect);
    $btnCloseSubscribe.on("click", _closeSelect);

    $contentBrandInfo.on("click", function(e){
        if ($(e.target).is(this)) {
            _closeSelect();
        };
    });


    /* Class Name */
    var

    selectOpen = "select-open";

    selectPanelActive = "content-select-active",
    selectPanelOpen = "content-select-open";

    /* Functions */

    function _openSelect()
    {
        $contentBrandInfo.addClass(selectPanelActive);

        setTimeout(function(){
            $contentBrandInfo.addClass(selectPanelOpen);
            $selectCity.addClass(selectOpen);
        },100);
    }

    function _closeSelect()
    {
        $contentBrandInfo.removeClass(selectPanelOpen);
        $selectCity.removeClass(selectOpen);

        setTimeout(function(){
            $contentBrandInfo.removeClass(selectPanelActive);
        },400);
    }

})();