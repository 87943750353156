$('.open-city_search').on('click', function(){
    $('.city_search').addClass('-open');
    $(".city_search-input").focus();
    // cursor at the end of the text (value)
    var inputVAlue = $(".city_search-input").val();
    $(".city_search-input").val('');
    $(".city_search-input").val(inputVAlue);

    if ($("script[src*='city-search.js']").length === 0) {
        $("body").append("<script src='" + stati + "/js/city-search.js'></script>");
    }
});