/**
 * Select City Input
 */

(function(){


	/* Events */

	$btnSelectCity.on("click", _openSelect);
	$btnCloseSelectCity.on("click", _closeSelect);

	$contentSelectCity.on("click", function(e){
		if ($(e.target).is(this)) {
			_closeSelect();
		};
	});


	/* Class Name */
	var

	selectOpen = "select-open";

	selectPanelActive = "content-select-active",
	selectPanelOpen = "content-select-open";

	/* Functions */

	function _openSelect()
	{
		$contentSelectCity.addClass(selectPanelActive);
		$html.addClass('html-block-scroll');


		setTimeout(function(){
			$contentSelectCity.addClass(selectPanelOpen);
			$selectCity.addClass(selectOpen);
		},100);
	}

	function _closeSelect()
	{
		$contentSelectCity.removeClass(selectPanelOpen);
		$selectCity.removeClass(selectOpen);
		$html.removeClass('html-block-scroll');


		setTimeout(function(){
			$contentSelectCity.removeClass(selectPanelActive);
		},400);
	}

	/**
	 * API
	 * 
	 * @type {Object}
	 */
	window.app.selectcity = {

		open: _openSelect,
		close: _closeSelect

	};

})();


/**
 * Select City Input
 */

(function(){

	/* Vars */

	$btnSelectCityGlobal = $(".open-select-city-global"),
	$btnCloseSelectCityGlobal = $(".close-select-city-global"),
	$contentSelectCityGlobal = $(".content-select-city-global"),
	$selectCityGlobal = $(".select-city-global");

	/* Events */

	$btnSelectCityGlobal.on("click", _openSelect);
	$btnCloseSelectCityGlobal.on("click", _closeSelect);

	$contentSelectCityGlobal.on("click", function(e){
		if ($(e.target).is(this)) {
			_closeSelect();
		};
	});


	/* Class Name */
	var

	selectOpen = "select-open";

	selectPanelActive = "content-select-active",
	selectPanelOpen = "content-select-open";

	/* Functions */

	function _openSelect()
	{
		$contentSelectCityGlobal.addClass(selectPanelActive);
		$html.addClass('html-block-scroll');

		setTimeout(function(){
			$contentSelectCityGlobal.addClass(selectPanelOpen);
			$selectCityGlobal.addClass(selectOpen);
		},100);
	}

	function _closeSelect()
	{
		$contentSelectCityGlobal.removeClass(selectPanelOpen);
		$selectCityGlobal.removeClass(selectOpen);
		$html.removeClass('html-block-scroll');

		setTimeout(function(){
			$contentSelectCityGlobal.removeClass(selectPanelActive);
		},400);
	}

	/**
	 * API
	 * 
	 * @type {Object}
	 */
	window.app.selectcityglobal = {

		open: _openSelect,
		close: _closeSelect

	};

})();