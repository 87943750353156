/* Set custom validation messages for fields with "required" attribute. */
document.addEventListener("DOMContentLoaded", function() {
    var elements = Array.prototype.slice.call(document.getElementsByTagName("INPUT"), 0).concat(Array.prototype.slice.call(document.getElementsByTagName("TEXTAREA"), 0)).concat(Array.prototype.slice.call(document.getElementsByTagName("SELECT"), 0));
    for (var i = 0; i < elements.length; i++) {
        elements[i].oninvalid = function(e) {
            e.target.setCustomValidity("");
            if (!e.target.validity.valid) {
                if(e.target.dataset && "errorMsg" in e.target.dataset){
                    e.target.setCustomValidity(e.target.dataset.errorMsg);
                }else {
                    e.target.setCustomValidity("This field cannot be left blank");
                }
            }
        };
        elements[i].oninput = function(e) {
            e.target.setCustomValidity("");
        };
    }
});