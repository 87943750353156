/**
 * Select Address Input
 */

(function(){


	/* Events */

	$btnSelectAddress.on("click", _openSelect);
	$btnCloseSelectAddress.on("click", _closeSelect);

	$contentSelectAddress.on("click", function(e){
		if ($(e.target).is(this)) {
			_closeSelect();
		};
	});


	/* Class Name */
	var

	selectOpen = "select-open";

	selectPanelActive = "content-select-active",
	selectPanelOpen = "content-select-open";

	/* Functions */

	function _openSelect()
	{
		$contentSelectAddress.addClass(selectPanelActive);

		setTimeout(function(){
			$contentSelectAddress.addClass(selectPanelOpen);
			$selectAddress.addClass(selectOpen);
		},100);
	}

	function _closeSelect()
	{
		$contentSelectAddress.removeClass(selectPanelOpen);
		$selectAddress.removeClass(selectOpen);

		setTimeout(function(){
			$contentSelectAddress.removeClass(selectPanelActive);
		},400);
	}

	/**
	 * API
	 * 
	 * @type {Object}
	 */
	window.app.selectaddress = {

		open: _openSelect,
		close: _closeSelect

	};

})();