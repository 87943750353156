/**
 * Small pice of javascript to show or hide 
 * the navbar on scroll and other events
 * 
 */

(function(){

	/* Events */

	//$(document).on("scroll", _onScroll );

	/* Cache */
	var

	scrollTopCache;

	/* Class Name */
	var

	classScroll = "navbar-hidden",
	classActive = "navbar-inner-active",
	classShadow = "navbar-sub-shadow",
	classHide = "navbar-replaced",
	classFixed = 'navbar-fixed';

	var	navH = $navbar.outerHeight(true);
		$smartBanner = $('#smartbanner:visible'),
		sCache = $smartBanner.outerHeight(true) + navH,
		initialSCache = $smartBanner.outerHeight(true) + navH,
		smartBannerH = 0;

	/* Functions */
	// function _onScroll(event){

	// 	var $el = $(this),
	// 		sTop = $el.scrollTop(),
	// 		smartBannerH = 0;

	// 		$smartBanner = $('#smartbanner:visible');
	// 		smartBannerH = $smartBanner.outerHeight(true) || 0;
	// 		initialSCache = smartBannerH + navH;


	// 	if(sTop <= initialSCache - navH){
	// 		$navbar.removeClass(classScroll).removeClass(classFixed);
	// 	}else{

	// 		if(sTop > sCache && sTop > initialSCache - navH){
	// 			//down
	// 			if($('.sh-profile-navigation-overlay.open').length === 0){
	// 				$navbar.addClass(classScroll);
	// 			}

	// 		}else{
	// 			// up
	// 			$navbar.removeClass(classScroll).addClass(classFixed);
	// 		}
	// 	}

	// 	sCache = sTop;
	// }

	function _show()
	{
		$navbar.removeClass(classScroll);
		$navbar.removeClass(classHide);
		$html.addClass('html-block-scroll');

		setTimeout(function(){
			$navbar.find('.navbar-inner').removeClass(classActive);
		}, 400);
	}

	function _hide()
	{
		$navbar.find('.navbar-inner').addClass(classActive);
		$navbar.addClass(classScroll);
		$navbar.addClass(classHide);
		$html.removeClass('html-block-scroll');
	}

	/**
	 * API
	 * 
	 * @type {Object}
	 */
	window.app.navbar = {

		show: _show,
		hide: _hide

	};

	/* End */

})();