    $(document).on("click", ".criteo", function() {
        var url = $(this).attr("href");
        $.post("/ajx/ajax.file.php", {
                fn: "setCriteoTags",
                "url": url,
                "city_id": smartyVARS.current_city
            },
            function(data) {
                // Criteo Sales tag
                window.criteo_q = window.criteo_q || [];
                window.criteo_q.push({ event: "setAccount", account: data.criteo.accountId }, { event: "setSiteType", type: data.criteo.type }, {
                    event: "trackTransaction",
                    id: data.criteo.trackTransaction,
                    item: [
                        { id: data.criteo.id, price: data.criteo.price, quantity: data.criteo.quantity }
                    ]
                });
            },
            "json");
    });
    if (smartyVARS.holiday_countdown) {
        var time = smartyVARS.holidayCountdown * 1000;

        if (new Date().getTime() < time) {
            var clock;

            clock = $('.count-wrap').FlipClock((time - new Date().getTime()) / 1000, {
                clockFace: 'DailyCounter',
                autoStart: true,
                callbacks: {
                    stop: function() {
                        console.log('The clock has stopped!');
                        $(".count-back").remove();
                    }
                },
                language: 'bg',
                countdown: true
            });
        } else {
            $(".count-back").remove();
        }



    }
    if (smartyVARS.add_smart_banner) {
        $.smartbanner({
            title: $sb_settings.title, // What the title of the app should be in the banner (defaults to <title>)
            author: $sb_settings.author, // What the author of the app should be in the banner (defaults to <meta name="author"> or hostname)
            price: $sb_settings.price, // Price of the app
            appStoreLanguage: $sb_settings.appStoreLanguage, // Language code for App Store
            inAppStore: $sb_settings.inAppStore, // Text of price for iOS
            inGooglePlay: $sb_settings.inGooglePlay, // Text of price for Android
            inAmazonAppStore: $sb_settings.inAmazonAppStore,
            GooglePlayParams: null, // Aditional parameters for the market
            icon: $sb_settings.icon, // The URL of the icon (defaults to <meta name="apple-touch-icon">)
            iconGloss: null, // Force gloss effect for iOS even for precomposed
            url: null, // The URL for the button. Keep null if you want the button to link to the app store.
            button: $sb_settings.button, // Text for the install button
            scale: 'auto', // Scale based on viewport size (set to 1 to disable)
            speedIn: 300, // Show animation speed of the banner
            speedOut: 400, // Close animation speed of the banner
            daysHidden: $sb_settings.daysHidden, // Duration to hide the banner after being closed (0 = always show banner)
            daysReminder: $sb_settings.daysReminder, // Duration to hide the banner after "VIEW" is clicked *separate from when the close button is clicked* (0 = always show banner)
            force: android ? 'android' : null, // Choose 'ios', 'android' or 'windows'. Don't do a browser check, just always show this banner
            hideOnInstall: true, // Hide the banner after "VIEW" is clicked.
            layer: false, // Display as overlay layer or slide down the page
            iOSUniversalApp: true, // If the iOS App is a universal app for both iPad and iPhone, display Smart Banner to iPad users, too.      
            appendToSelector: 'body', //Append the banner to a specific selector
            onInstall: function() {
                ga('send', 'event', { eventCategory: 'app-button', eventAction: 'android-smartbanner-install' });
            },
            onClose: function() {
                ga('send', 'event', { eventCategory: 'app-button', eventAction: 'android-smartbanner-close' });
            }
        });
    }
    //search city in brand page 

    if (addSeachCodeForBrands) {
        $.each(brandsCity, function(inx, val) {
            val.label = val.name;
        })
        $(function() {
            var projects = [];

            function autoCompleteEnterCallback(e){
                if(e.which === 13){
                    window.location.href = $("#brand-citys-search-mobile").data('redirect-to');
                }
            }

            if($("#brand-citys-search-mobile").length > 0){
                $("#brand-citys-search-mobile").autocomplete({
                        minLength: 0,
                        source: brandsCity,
                        focus: function(event, ui) {
                            $("#brand-citys-search-mobile").val(ui.item.label);
                            return false;
                        },
                        select: function(event, ui) {
                            $("#brand-citys-search-mobile").val(ui.item.label);

                            return false;
                        },
                        create: function(event, ui){
                            if($('.ios-device').length > 0){
                                $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
                            }
                        },
                        response: function(event, ui){
                            if(ui.content && ui.content.length === 1 && ($("#brand-citys-search-mobile").val() + "").toLowerCase() === (ui.content[0].label + "").toLowerCase()){
                                $("#brand-citys-search-mobile").on('keydown', autoCompleteEnterCallback).val(ui.content[0].label).data('redirect-to', '/' + ui.content[0].seo_link + '/' + smartyVARS.brandName);
                            }else{
                                $("#brand-citys-search-mobile").off('keydown', autoCompleteEnterCallback).removeData('redirect-to');
                            }
                        }
                })
            
                    .autocomplete("instance")._renderItem = function(ul, item) {
                        return $("<li>")
                            .append("<a href='" + item.seo_link + "/" + smartyVARS.brandName + "'>" + item.label + "</a>")
                            .appendTo(ul);

                    };
            }

        });




    }

function goToBrochurePage(url) {
    window.location.href = url;
}
    //End search city in brand page