/* GENERLA SELECT STUFF */

/*
 *	Select Open/Close on click
 */

$('.select-city').not('.select-hidden-mode').on("click", function(e) {
    $this = $(this);
    if (!$(e.target).is($this.find('.select-placeholder')) && !$(e.target).is($this.find('.select-placeholder span')) && !$(e.target).is($this.find('.icon-select-down'))) {
        return;
    };


    if ($this.hasClass("select-open")) {
        $this.removeClass('select-open');
    } else {
        $this.addClass('select-open');
        $this.find(".select-search input").focus();
        $(".mfp-wrap").animate({ scrollTop: $('.mfp-wrap').prop("scrollHeight") }, 600);
    }

})

/*
 *	Select Init Hide & Seek Plugin for easy/simple typehint search
 */

$(".select-options")
    .not('.select-hidden-mode .select-options')
    .not('.select-typeahead .select-options')
    .mCustomScrollbar({

        callbacks: {
            onInit: function() {

                $('.select-search input')
                    .not('.select-typeahead .select-search input')
                    .hideseek({
                        highlight: true,
                        list: ".select-options .mCSB_container",
                        nodata: window.textNoResults
                    });

            }
        }
    });

/*
 *	Select Options - on click set value if has input
 */

$(".select-option").not('.select-hidden-mode').on("click", function(e) {
    var $option = $(this);
    var $input = $option.parents(".select").find('input[type=hidden]');
    var $input2 = $option.parents(".select").find('input#selectCityName');

    if ($input.length === 0) {
        $input = $option.parents(".select").find('.select-value');
    }

    var $placeholder = $option.parents(".select").find('.select-placeholder');

    if ($input2.length !== 0) {
        $input2.val($option.text().replace(/\r?\n|\r/g, '').replace(/^\s\s*/, '').replace(/\s\s*$/, ''));
    }

    $input.val($option.data("value"));


    if ($(e.target).parents(".subscribe-form").length !== 0) {
        // removed js validation in favour of required attributes
        // $(".subscribe-form").valid();
    };

    if ($(e.target).parents(".subscribe-brand").length !== 0) {
        // removed js validation in favour of required attributes
        // $(".subscribe-brand").valid();
    };

    $placeholder.html('<span>' + $option.text() + '</span><ins class="icon-select-down"></ins>');

    $option.parents(".select").not('.select-hidden-mode').removeClass('select-open');

    if ($option.isWithin('.select-address')) {
        $("#submit-address").submit();
    }
});


/*
 *	Close All Selects on Click Out ( click on the body tag )
 */

$("body").on("click", function(event) {
    if (!$(event.target).isWithin(".select-city")) {
        $(".select-city").not('.select-hidden-mode').removeClass('select-open');
    };
});


/* OTHER SELECT STUFF */

/*
 *	Malls Select Sort by City
 */

$("#malls-sort").on("click", function(e) {

    var $this = $(this),
        url;

    if ($this.hasClass("open")) {
        $this.removeClass("open");
        url = $(e.target).val();

        window.location.href = url;
    } else {
        $this.addClass("open");
    };

});


/* Brand select address */
function enableSelectAddress() {
    var $select = $('#select-address');

    function getCurrentAddres(element) {
        return {
            address: element.find("option[selected]").text(),
            id: element.val()
        }
    }

    var address = getCurrentAddres($select);
}

if ($('#select-address').length !== 0) {
    enableSelectAddress();
};